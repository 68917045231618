import React from 'react';
import './About.css'; // Import the CSS file for the About page
import { FaUsers, FaLightbulb, FaHandsHelping } from 'react-icons/fa'; // Font Awesome icons
import teamImage from '../assets/team-image.png'; // Replace with actual image path
import passionImage from '../assets/passion-image.jpg'; // Replace with actual image path

const About = () => {
  return (
    <div>
      <HeroAbout /> {/* Hero Section */}

      <div className="about-container">
        <section className="our-story">
          <div className="story-content">
            <FaUsers className="about-icon" /> {/* Icon */}
            <h2>Our Story</h2>
            <p>
              Founded in 2018, SwiftHR was built by HR and technology experts who sought to simplify and automate the complex processes within human resources. Our team is dedicated to creating user-friendly software that addresses the unique challenges of modern HR management.
            </p>
            <img src={teamImage} alt="Our Team" className="team-image" />
          </div>
        </section>

        <section className="our-mission">
          <div className="mission-content">
            <FaLightbulb className="about-icon" /> {/* Icon */}
            <h2>Our Mission</h2>
            <p>
              To deliver cutting-edge HR solutions that help organizations stay organized, efficient, and ahead of the curve.
            </p>
          </div>
        </section>

        <section className="our-values">
          <h2>Our Values</h2>
          <div className="values-content">
            <img src={passionImage} alt="Our Values" className="values-image" />
            <ul className="values-list">
              <li><FaLightbulb className="list-icon" /> <strong>Innovation:</strong> Continuously enhancing our platform with the latest technology.</li>
              <li><FaUsers className="list-icon" /> <strong>User-Centric Design:</strong> Focusing on creating intuitive and accessible tools.</li>
              <li><FaHandsHelping className="list-icon" /> <strong>Commitment to Excellence:</strong> Ensuring that our clients receive top-notch service and support.</li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};

const HeroAbout = () => {
  return (
    <section className="hero-about">
      <div className="hero-about-content">
        <h1>About Us</h1>
      </div>
    </section>
  );
};

export default About;
