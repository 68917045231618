import React from 'react';
import './Feature.css'; // Updated CSS filename to reflect the specific feature

import { FaUserAlt, FaClipboardCheck, FaDollarSign, FaFileAlt, FaMobileAlt, FaCog, FaChartLine } from 'react-icons/fa'; // Icons

const HRFeatures = () => {
  return (
    <div>
      <HRHero />

      <div className="hr-features-container">
        <h2 className="hr-features-heading">Key Features</h2>

        <div className="hr-feature-card-grid">
          <div className="hr-feature-card">
            <FaUserAlt className="hr-feature-icon" />
            <h3>Employee Self-Service Portal</h3>
            <p>Empower your team with access to their personal data, leave requests, and pay slips, all from a single dashboard.</p>
          </div>

          <div className="hr-feature-card">
            <FaClipboardCheck className="hr-feature-icon" />
            <h3>Automated Payroll Processing</h3>
            <p>Save time and reduce errors with our fully integrated payroll system that handles taxes, benefits, and more.</p>
          </div>

          <div className="hr-feature-card">
            <FaCog className="hr-feature-icon" />
            <h3>Performance Management</h3>
            <p>Track goals, conduct reviews, and provide feedback effortlessly to keep your team motivated and aligned with objectives.</p>
          </div>

          <div className="hr-feature-card">
            <FaDollarSign className="hr-feature-icon" />
            <h3>Advanced Analytics</h3>
            <p>Make informed decisions with custom reports and data-driven insights that keep you ahead of trends.</p>
          </div>

          <div className="hr-feature-card">
            <FaMobileAlt className="hr-feature-icon" />
            <h3>Mobile Application</h3>
            <p>Enjoy 24/7 access to essential HR functions like viewing payslips, updating personal information, and submitting leave requests.</p>
          </div>

          <div className="hr-feature-card">
            <FaChartLine className="hr-feature-icon" />
            <h3>Integration with Third Party Systems</h3>
            <p>Ensure your HR processes are connected and streamlined across your entire business with easy integration to third-party systems.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const HRHero = () => {
  return (
    <section className="hr-hero-features">
      <div className="hr-hero-features-content">
        <h1>Features</h1>
      </div>
    </section>
  );
};

export default HRFeatures;

