import React from 'react';
import SwiftHRLogo from "../assets/SwiftHRLogo.png";
import facebookicon from "../assets/fb-icon.png";
import whatsappicon from "../assets/wa-icon.png";
import twittericon from "../assets/x-icon.png";
import linkedinicon from "../assets/in-icon.png";
import emailicon from "../assets/email-icon.png";
import swifthrmslogo2 from "../assets/swifthrms-logo-1.png";

import "./Footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  // Define the handleClick function
  const handleClick = (platform) => {
    console.log(`Redirecting to ${platform}`);
    // You can also add other logic here if needed
  };

  return (
    <div className="footer-section">
      <div className="footer-container">
        <div className="social-links">
          {/* X icon */}
          <a
            href="https://twitter.com/MicrohouseTechL"
            target="_blank"
            rel="noreferrer"
            onClick={() => handleClick('Twitter')}
          >
            <img className="social-icon" src={twittericon} alt="Twitter" />
          </a>
          {/* Whatsapp Icon */}
          <a
            href="https://wa.me/+254733915112"
            target="_blank"
            rel="noreferrer"
            onClick={() => handleClick('WhatsApp')}
          >
            <img className="social-icon" src={whatsappicon} alt="Whatsapp" />
          </a>
          {/* Facebook icon */}
          <a
            href="https://www.facebook.com/MicrohouseTech/"
            target="_blank"
            rel="noreferrer"
            onClick={() => handleClick('Facebook')}
          >
            <img className="social-icon" src={facebookicon} alt="Facebook" />
          </a>
          {/* Email Icon */}
          <a
            href="mailto:info@swifthrms.co.ke"
            target="_blank"
            rel="noreferrer"
            onClick={() => handleClick('Email')}
          >
            <img className="social-icon" src={emailicon} alt="Email" />
          </a>
          {/* LinkedIn icon */}
          <a
            href="https://www.linkedin.com/company/microhouse-technologies-limited/"
            target="_blank"
            rel="noreferrer"
            onClick={() => handleClick('LinkedIn')}
          >
            <img className="social-icon" src={linkedinicon} alt="LinkedIn" />
          </a>
        </div>
        <div className="footer-text">
          <p>
            Explore the potential of SwiftHR, where innovative HR solutions meet modern efficiency. 
            Connect with us through our platform for insights and support tailored to your business needs. 
            Dive into our resources or book a personalized demo today. Stay updated and engaged with us 
            on social media. Your journey towards streamlined human capital management starts here.
          </p>
        </div>
        <div className="swiftlogo">
          <img src={swifthrmslogo2} alt="SwiftHRMS" />
        </div>
      </div>
      
      <div className="footnote">
        <p className="copyright-statement">© Copyright 2024. All Rights Reserved</p>
      </div>
    </div>
  );
}
