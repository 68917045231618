import React from 'react';
import twittericon from '../assets/x-icon.png';
import whatsappicon from '../assets/wa-icon.png';
import facebookicon from '../assets/fb-icon.png';
import emailicon from '../assets/email-icon.png';
import linkedinicon from '../assets/in-icon.png';
import './ContactUs.css';

const ContactUs = () => {
    const handleClick = (platform) => {
      console.log(`Redirecting to ${platform}`);
    };
  
    return (
      <div className="contact-us-container">
        <section className="contact-content">
          {/* Hero Section */}
          <div className="hero-contact">
            <div className="hero-contact-content">
              <h1>Contact Us</h1>
              <p>Get in touch with us through any of the platforms below</p>
            </div>
          </div>
  
          {/* Contact Info */}
          <div className="contact-info">
            <h2>Reach Out to Us</h2>
            <p>We are always here to help and answer any questions you may have.</p>
  
            <div className="social-links">
              {/* Twitter Icon */}
              <a
                href="https://twitter.com/MicrohouseTechL"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleClick('Twitter')}
              >
                <img className="social-icons twitter" src={twittericon} alt="Twitter" />
              </a>
              {/* WhatsApp Icon */}
              <a
                href="https://wa.me/+254734095043"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleClick('WhatsApp')}
              >
                <img className="social-icons whatsapp" src={whatsappicon} alt="WhatsApp" />
              </a>
              {/* Facebook Icon */}
              <a
                href="https://www.facebook.com/MicrohouseTech/"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleClick('Facebook')}
              >
                <img className="social-icons facebook" src={facebookicon} alt="Facebook" />
              </a>
              {/* Email Icon - Open Gmail Compose Window */}
              <a
                href="https://mail.google.com/mail/?view=cm&fs=1&to=info@swifthrms.cloud"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleClick('Email')}
              >
                <img className="social-icons email" src={emailicon} alt="Email" />
              </a>
              {/* LinkedIn Icon */}
              <a
                href="https://www.linkedin.com/company/microhouse-technologies-limited/"
                target="_blank"
                rel="noreferrer"
                onClick={() => handleClick('LinkedIn')}
              >
                <img className="social-icons linkedin" src={linkedinicon} alt="LinkedIn" />
              </a>
            </div>
          </div>

          {/* Map Section */}
          <div className="contact-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.79907420042!2d36.79548490937245!3d-1.2951195356279477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f11ec922b2cd5%3A0xf2fdbed61c47d7e!2sMicrohouse%20Technologies%20Ltd!5e0!3m2!1sen!2ske!4v1713775303773!5m2!1sen!2ske"
              width="100%"
              height="500"
              loading="lazy"
              allowFullScreen
              title="Google Maps Location"
            ></iframe>
          </div>
        </section>
      </div>
    );
};

export default ContactUs;

