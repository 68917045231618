import React, { useState } from 'react';
import './Faq.css'; // Import the CSS for FAQ page
import { FaQuestionCircle, FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Icons for questions and arrows

const faqData = [
  {
    category: 'General',
    questions: [
      {
        question: 'What is SwiftHR?',
        answer: 'SwiftHR is an HRMS (Human Resource Management System) software that combines a variety of systems and processes to ensure easy management of human resources business processes and data. It includes modules for payroll, performance management, recruitment, time and attendance, employee self-service (ESS), and more.',
      },
      {
        question: 'How can SwiftHR help my organization?',
        answer: 'SwiftHR helps streamline HR processes, automates repetitive tasks, ensures accuracy in data handling, and reduces paperwork. It improves efficiency in areas such as payroll, attendance tracking, performance management, and recruitment, ultimately saving time and resources.',
      },
      {
        question: 'Is SwiftHR customizable to meet my company’s specific needs?',
        answer: 'Yes, SwiftHR is highly customizable. It can be tailored to your specific organizational requirements including custom workflows, reports, and integrations to align with your company’s goals and processes.',
      },
      {
        question: 'How secure is the data within SwiftHR?',
        answer: 'Security is our top priority. Our HRMS system utilizes advanced encryption, multi-factor authentication, and regular security audits to ensure that your data is safe from unauthorized access.',
      },
    ],
  },
  // Add other categories here...
];

const Faq = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const toggleQuestion = (index) => {
    if (activeQuestion === index) {
      setActiveQuestion(null);
    } else {
      setActiveQuestion(index);
    }
  };

  return (
    <div>
      {/* Hero Section */}
      <div className="hero-faq">
        <div className="hero-faq-content">
          <h1>Frequently Asked Questions</h1>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="faq-container">
        {faqData.map((category, categoryIndex) => (
          <div key={categoryIndex} className="faq-category">
            <h2 className="faq-category-title">{category.category}</h2>
            {category.questions.map((faq, i) => (
              <div key={i} className="faq-item">
                <button
                  className={`faq-question ${activeQuestion === i ? 'active' : ''}`}
                  onClick={() => toggleQuestion(i)}
                >
                  <FaQuestionCircle className="faq-icon" />
                  {faq.question}
                  <span className="arrow">
                    {activeQuestion === i ? <FaChevronUp /> : <FaChevronDown />}
                  </span>
                </button>
                <div className={`faq-answer ${activeQuestion === i ? 'open' : ''}`}>
                  <p>{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
