import React from 'react';
import './SplashScreen.css'; // Import the CSS file for the animation

const SplashScreen = () => {
  return (
    <div className="splash-container">
      <div className="logo-animation">
        <h1 className="swift-logo">Swift<span>HR</span></h1>
        <div className="loading-dots">
          <span className="dot"></span>
          <span className="dot"></span>
          <span className="dot"></span>
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
