import React from 'react';
import './Modules.css'; // Import the CSS file
import { FaCogs, FaUsers, FaMoneyCheckAlt, FaClipboardList, FaClock, FaChartLine, FaFileAlt } from 'react-icons/fa'; // Icons from react-icons

const Modules = () => {
  return (
    <div className="modules-container">
      <Hero />
      <section className="module-section">
        <div className="module-grid">
          <ModuleCard
            icon={<FaCogs className="module-icon" />} // Icon for Core HR
            title="Core HR"
            description="Centralize all HR functions with SwiftHR’s comprehensive Core HR module. From employee management to leave tracking, our platform simplifies and streamlines your HR operations."
            points={[
              "Employee Management: Consolidate and manage all employee data efficiently.",
              "Leave & Air Ticket Management: Simplify leave applications and manage travel arrangements.",
              "Assets Inventory Management: Track and manage company assets with ease.",
              "Organizational Management: Handle organizational structures and hierarchies effectively.",
              "Letter Management: Automate the creation and management of official documents."
            ]}
          />
          <ModuleCard
            icon={<FaUsers className="module-icon" />} // Icon for Employee Self-Service
            title="Employee Self-Service"
            description="Empower your employees with easy access to HR services, from submitting leave requests to downloading payslips, all through a user-friendly interface."
            points={[
              "Swift Applications: Submit leave requests, expense claims, and loan applications seamlessly.",
              "Real-Time Attendance Tracking: Monitor attendance data instantly.",
              "Instant Document Access: Download payslips and important documents with one click.",
              "Up-to-Date Leave Information: Check leave balances and holiday entitlements easily.",
              "Quick Resource Access: Access FAQs and company policies on demand."
            ]}
          />
          <ModuleCard
            icon={<FaMoneyCheckAlt className="module-icon" />} // Icon for Payroll Management
            title="Payroll Management"
            description="Transform your payroll process with automation, ensuring accuracy and efficiency in every payroll cycle."
            points={[
              "Automated Payroll Processing: Eliminate errors with automated salary calculations and deductions.",
              "Seamless Attendance Integration: Link attendance data directly with payroll for accurate processing.",
              "Flexible Payment Options: Accommodate various payment cycles and methods.",
              "Integrated Financial Management: Sync payroll with accounting systems for simplified financial reporting."
            ]}
          />
          <ModuleCard
            icon={<FaClipboardList className="module-icon" />} // Icon for Reports
            title="Reports"
            description="Generate real-time, detailed reports that provide actionable insights to drive your business forward."
            points={[
              "Dynamic Reporting: Customize reports by department, employment status, or custom fields.",
              "In-Depth Analysis: Tailor reports to meet your specific needs, from payroll expenses to employee data."
            ]}
          />
          <ModuleCard
            icon={<FaClock className="module-icon" />} // Icon for Time and Attendance
            title="Time and Attendance"
            description="Manage attendance with ease, from automated daily reports to shift scheduling and overtime tracking."
            points={[
              "Automated Daily Attendance Report: Consolidate attendance data into a single, comprehensive report.",
              "Punch Report: Track employee clock-ins and clock-outs with precision.",
              "Overtime Report: Monitor and manage overtime effectively to ensure compliance.",
              "Shift Roaster Report: Schedule and adjust shifts with ease.",
              "Manual Process & Attendance Adjustment: Manually input and correct attendance data as needed."
            ]}
          />
          <ModuleCard
            icon={<FaChartLine className="module-icon" />} // Icon for Performance Management
            title="Performance Management"
            description="Elevate your workforce with a smart performance management system that ensures transparent and fair appraisals."
            points={[
              "Progress Tracking: Keep tabs on appraisal progress and employee performance.",
              "Customizable Appraisals: Align performance reviews with your company’s values and goals.",
              "Constructive Feedback: Provide meaningful recommendations to support employee growth."
            ]}
          />
          <ModuleCard
            icon={<FaFileAlt className="module-icon" />} // Icon for Hiring
            title="Hiring"
            description="Simplify your recruitment process from job posting to onboarding with our automated hiring module."
            points={[
              "Smart Recruitment: Automate resume screening and candidate matching.",
              "Streamlined Onboarding: Seamlessly transition new hires into your organization."
            ]}
          />
        </div>
      </section>
    </div>
  );
};

const Hero = () => {
  return (
    <section className="hero-modules">
      <div className="hero-modules-content">
        <h1>Our Modules</h1>
      </div>
    </section>
  );
};

const ModuleCard = ({ icon, title, description, points }) => {
  return (
    <div className="module-card">
      {icon}
      <h2 className="module-title">{title}</h2>
      <p className="module-description">{description}</p>
      <ul className="module-bullet-points">
        {points.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>
    </div>
  );
};

export default Modules;

