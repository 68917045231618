import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';  // Import Navbar component
import Footer from './components/Footer';  // Import Footer component
import Home from './Pages/Home';  // Import the Home component
import About from './Pages/About';  // Import the About component
import Feature from './Pages/Feature';  // Import the Features component
import Modules from './Pages/Modules';  // Import the Features component
import Faq from './Pages/Faq';
import ContactUs from './Pages/ContactUs';
import SplashScreen from './components/SplashScreen';  // Import SplashScreen component

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate the loading time (e.g., preloading assets)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the time based on actual loading times

    return () => clearTimeout(timer); // Cleanup the timeout
  }, []);

  if (loading) {
    return <SplashScreen />; // Show the splash screen while loading
  }

  return (
    <Router>
      <div>
        <Navbar />  {/* Navbar will appear on every page */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/feature" element={<Feature />} />
          <Route path="/modules" element={<Modules />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contactUs" element={<ContactUs />} />
          {/* Add more routes as needed */}
        </Routes>
        <Footer />  {/* Footer will appear on every page */}
      </div>
    </Router>
  );
}

export default App;


