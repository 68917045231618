import React, { useEffect } from 'react';
import './Home.css'; // Import the CSS file

const Home = () => {
  useEffect(() => {
    // IntersectionObserver to handle the fade-in on scroll
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 } // Element appears when 10% of it is in view
    );

    const fadeElements = document.querySelectorAll('.fade-in');
    fadeElements.forEach((el) => observer.observe(el));

    return () => {
      fadeElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <div>
      <Hero />
      <section className="introduction fade-in">
        <div className="intro-text">
          <h3>Your Strategic Partner in Human Capital Management</h3>
          <p>
            Effortlessly navigate the complexities of employee management with our streamlined system, 
            designed to synchronize with your workforce dynamics instantaneously and without the tedious 
            wait times typically associated with traditional HR processes.
          </p>
        </div>
        <div className="intro-text">
          <h3>Empower Your Staff to Take Charge Anytime, Anywhere</h3>
          <p>
            With SwiftHR, your team can efficiently handle leave requests, profile changes, 
            and various HR tasks from anywhere. Additionally, the convenience of secure, 
            mobile access to payslips simplifies payroll management on the move.
          </p>
        </div>
      </section>
      <Features />
    </div>
  );
};

const Hero = () => {
  return (
    <section className="hero fade-in">
      <div className="hero-content">
        <p>Introducing</p>
        <div className="hero-logo"></div> {/* Replace <img> with a <div> */}
        <p>Human Resource Management system</p>
      </div>
    </section>
  );
};

const Features = () => {
  return (
    <div className="features-container fade-in">
      <h2 className="features-header">Highlighted Features of SwiftHR</h2>
      <div className="feature-cards-container">
        <div className="feature-card core-hr">
          <div className="feature-icon-wrapper">
            <img
              src="/images/Employee Self Service.png"
              alt="Core HR icon"
              className="feature-icon"
            />
          </div>
          <div className="feature-info">
            <h3>Automated Payroll Processing</h3>
            <p>
              Eliminate manual calculations and errors with SwiftHR's automated payroll processing. Our system ensures that all payroll activities, from salary calculations to tax deductions, are handled accurately and efficiently.
            </p>
          </div>
        </div>

        <div className="feature-card payroll">
          <div className="feature-icon-wrapper">
            <img
              src="/images/Recruitement.png"
              alt="HR and Payroll icon"
              className="feature-icon"
            />
          </div>
          <div className="feature-info">
            <h3>Real-Time Attendance Tracking</h3>
            <p>
              Keep track of employee attendance in real-time with SwiftHR. Integrate with biometric systems to monitor lateness, early departures, overtime, and absences instantly, ensuring up-to-date records.
            </p>
          </div>
        </div>

        <div className="feature-card ess">
          <div className="feature-icon-wrapper">
            <img
              src="/images/phone.png"
              alt="ESS icon"
              className="feature-icon"
            />
          </div>
          <div className="feature-info">
            <h3>Mobile App Accessibility</h3>
            <p>
              Manage HR tasks on the go with SwiftHR's mobile app, available on iOS and Android. Whether you're approving leave requests or checking payslips, our app keeps you connected and in control.
            </p>
          </div>
        </div>

        <div className="feature-card time-attendance">
          <div className="feature-icon-wrapper">
            <img
              src="/images/document access.png"
              alt="Time and Attendance icon"
              className="feature-icon"
            />
          </div>
          <div className="feature-info">
            <h3>Secure Cloud Storage</h3>
            <p>
              Store all your HR data securely in the cloud with SwiftHR. Our platform offers built-in security features to ensure that your organization's data is always protected and accessible when needed.
            </p>
          </div>
        </div>

        <div className="feature-card employee">
          <div className="feature-icon-wrapper">
            <img
              src="/images/performance.png"
              alt="Employee Self Service icon"
              className="feature-icon"
            />
          </div>
          <div className="feature-info">
            <h3>Employee Self-Service</h3>
            <p>
              Empower your employees with access to personal and salary details, the ability to apply for leaves, advances, and reimbursements directly from their dashboard, streamlining HR processes and improving transparency.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
