import { NavLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import "./Navbar.css";
import swiftnewlogo from "../assets/HomePageBanner.png";

export default function Navbar() {
  const [isActiveL, setIsActiveL] = useState(false);
  const navigate = useNavigate();

  // Toggle the mobile menu
  const toggleActiveClass = () => {
    setIsActiveL(!isActiveL);
  };
  
  return (
    <div className='navbar-wrapper'>
      <div className="navbar-container">
        {/* Make the logo clickable by wrapping it in a NavLink */}
        <NavLink exact to="/">
          <img
            className="swiftlg"
            src={swiftnewlogo}
            alt="Swift HR Logo"
          />
        </NavLink>
        <div className={`navlinks ${isActiveL ? "navlinksactive" : ""}`}>
          <NavLink onClick={toggleActiveClass} className='navlink' activeClassName='active' exact to="/">Home</NavLink>
          <NavLink onClick={toggleActiveClass} className='navlink' activeClassName='active' to="/feature">Features</NavLink>
          <NavLink onClick={toggleActiveClass} className='navlink' activeClassName='active' to="/about">About</NavLink>
          <NavLink onClick={toggleActiveClass} className='navlink' activeClassName='active' to="/modules">Modules</NavLink>
          <NavLink onClick={toggleActiveClass} className='navlink' activeClassName='active' to="/faq">FAQ</NavLink>
          <NavLink onClick={toggleActiveClass} className='navlink' activeClassName='active' to="/contactus">Contact</NavLink>
        </div>
        
        <button
          className='demo-btn'
          onClick={() => navigate("/contact")}
        >
          Go to test Demo
        </button> 

        <div className={`hamburger ${isActiveL ? 'active' : ''}`} onClick={toggleActiveClass}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </div>
    </div>
  );
}

